<template>
  <NuxtLink
    :to="`/resources/${props.resourceArticle.slug}`"
    class="block space-y-16 rounded-3xl bg-white p-5"
  >
    <div class="flex items-start justify-between">
      <span
        class="type-label uppercase text-black/50"
        v-text="
          $dayjs(
            props.resourceArticle.publishedAt ||
              props.resourceArticle.createdAt,
          ).format('D MMMM YYYY')
        "
      />
      <div
        class="aspect-square w-36 overflow-hidden rounded-xl md:w-56 md:rounded-2xl lg:w-40"
      >
        <PayloadImage
          class="h-full w-full object-cover"
          :image="resourceArticle.image"
          :sizes="{
            default: '144px',
            md: '320px',
          }"
        />
      </div>
    </div>
    <div class="max-w-xs space-y-2 md:space-y-3">
      <h3 class="type-label uppercase" v-if="category" v-text="category.name" />
      <h2
        class="type-heading-5 font-medium"
        v-text="props.resourceArticle.title"
      />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { ResourceArticle } from '#payload/types'

interface ResourceArticleCardProps {
  resourceArticle: ResourceArticle
}

const props = defineProps<ResourceArticleCardProps>()
const category = useRelationshipField(props.resourceArticle.category)
</script>
